<template>
  <div class="panel" :class="{'set-back' : !state.lightTheme}">
      <Sidebar v-if="$route.name!=='P2P'"/>
      <div class="panel-page">
        <Dashboard v-if="$route.name=='Dashboard'"/>
        <Wallet v-if="$route.name=='Wallet'"/>
        <Authentication v-if="$route.name=='Profile'"/>
        <InviteFriends v-if="$route.name=='InviteFriends'"/>
        <Settings v-if="$route.name=='Settings'"/>
        <Notifications v-if="$route.name=='Notifications'"/>
        <OTC v-if="$route.name=='OTC'"/>
        <Ticket v-if="$route.name=='Ticket'"/>
        <Level v-if="$route.name=='Level'"/>
        <P2P v-if="$route.name=='P2P'"/>
        <History v-if="$route.name=='History'"/>

      </div>
  </div>
</template>

<script>
import InviteFriends from './InviteFriends'
import Notifications from './Notifications'
import OTC from './OTC/OTC'
import Settings from './Settings'
import Dashboard from './Dashboard/Dashboard'
import Wallet from './Wallet/Wallet'
import Authentication from './Authentication/Authentication'
import Sidebar from './Sidebar'
import Ticket from './Ticket'
import Level from './Level'
import P2P from './P2P/P2P'
import History from './History/History'
export default {
  name: 'Panel',
  components: {
    Dashboard,
    Sidebar,
    Wallet,
    Authentication,
    InviteFriends,
    Settings,
    Notifications,
    OTC,
    Ticket,
    Level,
    P2P,
    History,

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.panel{
    width: 100%;
    padding: 0px 0px 16px 0px;
    display: flex;
    // flex-grow: 1;
    column-gap: 36px;
    margin-top: 75px;
    min-height: 100vh;
 
}

.panel-page{
  display: flex;
  flex: 4;
}
@media(max-width:900px){
  .panel{
    width: 95%;
    column-gap: 12px;
  }
}
@media(max-width:500px){
  .panel{
    width: 98%;
    column-gap: 6px;
  }
}

</style>
