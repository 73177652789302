<template>
<div class="navbar">
    <a href="/" @click.prevent="$router.push('/')" class="logo-link">
        <img src="@/assets/public/Logo.svg" alt=""></a>
    <inline-svg class="icon sidebar-icon" @click="side_open=!side_open" :src="require('../../assets/icons/resp-sidebar-open.svg')" />
    <div :class="{links:true,'panel-navbar-links':$route.path.includes('Panel')}">
        <div class="link-container" v-for="(link,index) in filteredLinks()" :key="index">
            <a :href="'/'+link.path" @click.prevent="$router.push('/'+link.path)" class="link">{{link.title}}</a>
            <div class="line" />
        </div>
    </div>
    <div class="btns">
        <div class="user-profile" v-if="state.token" @click="$router.push('/Panel')">
            <inline-svg class="profile-icon" :src="require('../../assets/icons/Profile.svg')" />
            <span>{{state.userInfo.firstName+' '+state.userInfo.lastName}}</span>
        </div>
        <button v-if="!state.token" @click="$router.push('/register')" class="btn">ثبت نام</button>
        <button v-if="!state.token" @click="$router.push('/login')" class="btn">ورود</button>
        <!-- <inline-svg class="icon"  :src="require('../../assets/icons/navsearch.svg')"/> -->

        <!-- <a href="/Panel/Notifcations" v-if="state.token" class="notif-icon" @click.prevent="$router.push('/Panel/Notifications')">
            <inline-svg class="icon" :src="require('../../assets/icons/bell.svg')" />
            <div class="message-count">{{unreadedCount}}</div>
        </a> -->
        <div class="icons-container">
            <img class="icon" @click="toggleTheme" src="../../assets/icons/moon.svg" v-if="state.lightTheme">
            <img class="icon" @click="toggleTheme" src="../../assets/icons/sun.svg" v-else>
        </div>
    </div>
    <div class="sidebar" :style="{width:(side_open)?'150px':'0px' }">
        <inline-svg class="close-icon" @click="side_open=false" :src="require('../../assets/icons/closecard.svg')" />
        <div class="link-container" v-for="(link,index) in filteredLinks()" :key="index">
            <a :href="'/'+link.path" @click.prevent="$router.push('/'+link.path);side_open=false" class="link">{{link.title}}</a>
            <div class="line" />
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Navbar',
    props: {
        msg: String,

    },
    data() {
        return {
            unreadedCount: 0,
            theme: true,
            links: [{
                    title: 'صفحه ی اصلی',
                    path: ''
                },
                {
                    title: 'بازارها',
                    path: 'Markets'
                },
                {
                    title: 'معامله آسان',
                    path: 'panel/otc'
                },
                {
                    title: 'معامله حرفه ای',
                    path: 'panel/p2p'
                },
                {
                    title: 'کارمزدها',
                    path: 'fee'
                },
                {
                    title: 'درباره ما',
                    path: 'AboutUs'
                },
                {
                    title: 'قوانین و مقررات',
                    path: 'Terms'
                },

                // {
                //     title: ' معاملات پیشرفته',
                //     path: 'Panel/P2P/TOMAN/BITCOIN'
                // },
                // {
                //     title: ' معلاملات ساده',
                //     path: 'Panel/OTC'
                // },
                {
                    title: ' تماس با ما',
                    path: 'ContactUs'
                },
                {
                    title: ' بلاگ',
                    path: 'Blog'
                },
            ],
            side_open: false

        }
    },
    created() {
        document.addEventListener('click', (e) => {
            if (!e.target.closest('.sidebar, .sidebar-icon')) {
                this.side_open = false
            }
        })
    },
    methods: {
        async getUnreadMessagesCount() {
            const res = await this.$axios.get('/users/notifications', {
                params: {
                    read: false
                }
            })
            if (res) {
                this.unreadedCount = res.totalElements
            }

        },
        toggleTheme() {
            this.state.lightTheme = !this.state.lightTheme

        },
        filteredLinks() {

            return this.links.filter(link => {
                let flag = this.state.token ? true : !(link.path.includes('P2P') || link.path.includes('OTC'))
                if (flag) {
                    return link
                }

            })
        }
    },
    mounted() {
        if (this.state.token)
            this.getUnreadMessagesCount()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.message-count {
    position: absolute;
    border-radius: 50%;
    background: red;
    width: 11px;
    right: -3px;
    bottom: -1px;
    height: 13px;
    font-size: clamp(10px, 1.2vw, 12px);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

}

.notif-icon {
    display: flex;
    align-items: center;
    position: relative;

}

.close-icon {
    margin-right: 16px;
    margin-bottom: 30px;
    cursor: pointer;
    stroke: var(--authcard-icon);
}

.sidebar-icon {
    display: none;
}

.sidebar {
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    background: var(--navbar-bg);
    top: 60px;
    padding: 20px 0px 30px 0px;
    border-radius: 15px;
    box-shadow: -8px -8px 24px rgba(0, 0, 0, 0.1), 8px 8px 24px rgba(0, 0, 0, 0.1), inset -8px -8px 24px 1px rgba(0, 16, 85, 0.15), inset 8px 8px 24px 1px rgba(0, 93, 164, 0.1);
    overflow-x: hidden;
    white-space: nowrap;
    transition: width 0.5s;
    display: none;

}

.sidebar>div {
    padding-right: 16px;
}

.user-profile {
    border-radius: 50px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    padding: 6px 23px;
    background: var(--navbar-profile-bg);
    color: var(--navbar-profile-text);
    stroke: var(--navbar-profile-icon);
    font-size: clamp(12px, 1.4vw, 16px);
    column-gap: 15px;
    border: 0.4px solid var(--navbar-profile-border);
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    top: 0;
    padding: 0px 24px;
    flex-grow: 1;
    // width: 100%;
    left: 0;
    right: 0;
    column-gap: 10px;
    // border-radius: 50px;
    background: var(--navbar-bg);
    position: fixed;
    z-index: 40;

}

.logo-link {
    color: var(--navbar-link);
    display: flex;
    align-items: center;

}

.icon {
    stroke: var(--navbar-icon);
    cursor: pointer;
}

.line {
    opacity: 0;
    background: var(--navbar-link);
    height: 1px;
    width: 100%;
    transition: opacity 0.6s;
}

.link-container {
    display: flex;
    flex-direction: column;

}

.links {
    display: flex;
    justify-content: space-between;
    column-gap: 35px;
}

.btns {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.link-container:hover>.line {
    opacity: 1;
}

.link-container:hover>.link {
    color: var(--navbar-btn-text-hover);
    opacity: 1;

}

.btn {
    background: var(--navbar-btn-bg);
    border: 0.3px solid var(--navbar-btn-border);
    color: var(--navbar-btn-text);
    padding: clamp(3px, 0.5vw, 6px) clamp(16px, 1.9vw, 24px);
    display: flex;
    align-items: center;
    border-radius: 50px;
}

.link {
    color: var(--navbar-link);
    font-size: 14px;
    opacity: 0.8;
    font-weight: 300;
}

.icons-container {
    border: 1px solid  var(--range-background);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width:940px) {
    .links {
        display: none;
    }

    .sidebar-icon {
        display: block;
    }

    .sidebar {
        display: flex;
    }

}

// @media(max-width:900px) {
//     .navbar {
//         width: 95%;
//     }
// }
@media(max-width:400px) {
    .user-profile {
        span {
            display: none;
        }
    }
}
</style>
