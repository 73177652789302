<template>
<div class="depsoits-section">
    <div class="head">
        <div class="depsoits">
            <div class="section-title">
                <span>دارایی ها</span>
                <div class="tomanBtns-marketSwitch">
                    <DropDown class="dropfilter" @choose="(choice)=>{marketType=choice;$emit('changeMarket',choice)}" :items="[{label:'بازار',value:'MARKET'},{label:'صرافی',value:'EXCHANGE'}]" />
                </div>
            </div>
            <div class="wallets">
                <div>
                    <div class="wallets-header">کیف پول ها</div>
                    <div class="wallets-value">کیف پول {{marketDict[marketType]}}</div>
                </div>
                <div>
                    <div class="wallets-header">ارزش کل دارایی</div>
                    <div class="wallets-value">{{$toLocal(totalPriceOfCredits,0)}} تومان</div>
                </div>
                <div>
                    <div class="wallets-header">دارایی کیف پول تومان</div>
                    <div class="wallets-value">{{$toLocal(tomanBalance,0)}} تومان</div>
                </div>

            </div>

        </div>
        <div class="toman-btns">
            <button class="toman-withdraw" >برداشت از کیف پول تومان</button>
            <button class="toman-depsoit">واریز به کیف پول تومان</button>
        </div>
        <!-- <Chart :wallets="wallets" /> -->
    </div>

    <div class="table-container">
        <table v-if="!loading">
            <tr class="table-header">
                <td>ارز</td>
                <td>دارایی</td>
                <!-- <td>ارزش فعلی</td> -->
                <td>
                    معادل(تومان)
                </td>
                <td>
                    در معامله
                </td>
                <td class="resp-1">تغییرات بازار</td>
                <td>
                    کیف پول
                </td>
                <!-- <td class="resp-1">قیمت خرید</td> -->
                <!-- <td>سود شما</td>
                <td class="resp-2">درصد از پرتفوی</td> -->
            </tr>
            <tr v-for="(coin,index) in sorted.filter(coin=>coin[0]!='TOMAN')" :key="index" class="table-row">
                <td>
                    <div class="coin-title">
                        <img class="crypto-icon" :src="require('../../../../public/coins/'+coin[0]+'.png')" />
                        <div>{{$coins[coin[0]].code}}</div>
                    </div>
                </td>
                  <td>{{$toLocal(coin[1].credit,$decimal[coin[0]])}}</td>
                <td>{{$toLocal(coin[1].wholePriceOfCredit,$decimal[coin[0]])}}</td>
                <td>{{$toLocal(coin[1].blockedCredit,$decimal[coin[0]])}}</td>
                
                <!-- <td>{{$toLocal(coin[1].wholePriceOfCredit,0)}}</td> -->
                <td class="change resp-1" :class="{'positive-change':coin[1].change>0 , 'negative-change':coin[1].change<0}">

                    {{$toLocal(Math.abs(coin[1].change),2)}}%
                    <span v-if="coin[1].change > 0">+</span>
                    <span v-else>-</span>
                    <!-- <inline-svg :class="{'negative-arrow':coin[1].change<0}" :src="require('../../../assets/icons/value-arrow.svg')" /> -->
                </td>
                <td>
                    مشاهده ی کیف پول 
                </td>
                <!-- <td>{{$toLocal(coin[1].price,0)}}</td> -->
                <!-- <td class="profit"> {{$toLocal(Math.abs(coin[1].change),2)}}%
                    <inline-svg :class="{'negative-arrow':coin[1].change<0}" :src="require('../../../assets/icons/value-arrow.svg')" />
                </td> -->
                <!-- <td class="portfoy-field resp-2">{{Math.round(totalPriceOfCredits?coin[1].wholePriceOfCredit/totalPriceOfCredits*100:0)}}%
                
                    <div class="portfoy-bg ">
                        <div class="portfoy-fill" :style="{width:coin[1].wholePriceOfCredit/totalPriceOfCredits+'%'}" />
                    </div>
                </td> -->
            </tr>

        </table>
    </div>
    <!-- <button class="all-depsoit-btn" @click="$router.push('Wallet')">مشاهده کل دارایی</button> -->
    <TomanWithdraw @modalToggle="(value)=>modalType=value" v-if="modalType=='TomanWithdraw'" :cash="tomanBalance" />
    <TomanDepsoit @modalToggle="(value)=>modalType=value" v-if="modalType=='TomanDepsoit'" />

</div>
</template>

<script>
import TomanWithdraw from '../../Modals/TomanWithdraw.vue'
import TomanDepsoit from '../../Modals/TomanDepsoit.vue'
// import Chart from './Chart'
import DropDown from '../../tools/DropDown'
import * as variables from '../../../library/variables.json'
export default {
    name: 'Depsoits',
    computed: {
        sorted() {
            let entireRes = Object.entries(this.wallets)
            const sortCoins = this.$walletSortCoins
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            return entireRes

        },
        tomanBalance() {
            if (this.wallets['TOMAN']) {
                const temp = this.wallets['TOMAN']
                return temp.credit
            }
            return 0
        },

    },
    watch: {
        marketType() {
            if (this.marketType == 'EXCHANGE')
                this.getExchangeCoins()
            else
                this.getP2PCoins()
        }
    },

    data() {
        return {
            loading: false,
            coins: variables.cryptos,
            totalValue: 34334344,
            tomanTotalValue: 74372323,
            wallets: [],
            totalPriceOfCredits: 0,
            marketType: "P2P",
            marketDict: {
                'P2P': 'بازار',
                'EXCHANGE': 'صرافی'
            },
            modalType: ''
        }
    },
    methods: {
        async getP2PCoins() {
            this.totalPriceOfCredits = 0
            this.loading = true
            this.state.loading = true
            let cryptos
            let crypto
            const [res, ] = await this.$http.get(`/wallets/customer-addresses?walletType=P2P`)
            if (res) {
                this.wallets = res
            }
            await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                }
            }).then(response => cryptos = response.content.filter(coin => coin.marketType.includes('TOMAN')))
            for (const [key, value] of Object.entries(this.wallets)) {

                if (key !== 'TOMAN') {
                    crypto = cryptos.find(coin => coin.marketType.includes(key))
                    if (crypto) {
                        value.buyPrice = crypto.buyPrice
                        value.sellPrice = crypto.sellPrice
                        value.change = crypto.last24HourChange
                        value.price = crypto.lastPrice

                        this.totalPriceOfCredits += value.wholePriceOfCredit
                    }
                }
                this.loading = false
                this.state.loading = false
            }

        },

        async getExchangeCoins() {
            this.totalPriceOfCredits = 0
            this.loading = true
            this.state.loading = true

            let cryptos
            let crypto
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => cryptos = response)

            for (const [key, value] of Object.entries(this.wallets)) {
                if (key !== 'TOMAN') {
                    crypto = cryptos.find(coin => coin.destCoinSymbol === key)
                    if (crypto) {
                        value.buyPrice = crypto.buyPrice
                        value.sellPrice = crypto.sellPrice
                        value.change = crypto.price24hrChangePercent
                        value.price = crypto.price
                        this.totalPriceOfCredits += value.wholePriceOfCredit

                    }

                }
            }
            this.loading = false
            this.state.loading = false

        }
    },
    components: {
        // Chart,
        TomanDepsoit,
        TomanWithdraw,
        DropDown

    },
    async mounted() {
        await this.getP2PCoins()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.tomanBtns-marketSwitch {
    display: flex;
    column-gap: 10px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100px;
}

.dropfilter {
    z-index: 3;
    flex-grow: 1
}

.toman-btns {
    flex-grow: 2;
    flex-basis: 200px;
    display: flex;
    flex-wrap: wrap;
    font-size: clamp(13px, 1vw, 14px);
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 8px;
    row-gap: 8px;
    flex-direction: column;
}

.toman-btns>button {
    white-space: nowrap;
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 20px;
    border-radius: 50px;

}

.toman-withdraw {
    background: var(--toggle-comp-inactive-bg);
    color: var(--toggle-comp-inactive-text);
    // box-shadow: -8px -8px 24px rgba(0, 0, 0, 0.1), 8px 8px 24px rgba(0, 0, 0, 0.1),
    //     inset -8px -8px 24px 1px rgba(0, 16, 85, 0.15), inset 8px 8px 24px 1px rgba(0, 93, 164, 0.1);
}

.toman-depsoit {
    background: var(--normal-btn);
    /* background: white; */
    // box-shadow: -8px -8px 24px rgba(255, 191, 0, 0.2), 8px 8px 24px rgba(255, 191, 0, 0.2), inset -1px -1px 16px 1px rgba(192, 144, 0, 0.5), inset 1px 1px 16px 1px rgba(192, 144, 0, 0.5);
    color: #FFFFFF;

}

.table-container {
    max-height: 300px;
    margin-top: 9px;

    overflow-y: auto;
}

.head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 16px;
    border-bottom: 1px solid #CED1E4;
    padding-bottom: 10px;
}

.depsoits {
    flex-grow: 1;
    max-width: 560px;

}

.portfoy-bg {
    position: relative;
    width: 100px;

    height: 3px;
    border-radius: 2px;
    background: #D4D1FF;
}

.portfoy-fill {
    position: absolute;
    right: 0px;
    background: #FF2D78;
    top: 0px;
    height: 3px;
    border-radius: 2px;

}

.portfoy-field {
    display: flex;
    justify-content: space-between;
    column-gap: 2px;
    align-items: center;
}

.all-depsoit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: var(--dashboard-depsoits-btn-bg);
    color: var(--dashboard-depsoits-btn-text);
    height: 35px;
    padding: 0px 16px;
    box-shadow: -8px -8px 24px rgba(0, 0, 0, 0.1), 8px 8px 24px rgba(0, 0, 0, 0.1), inset -8px -8px 24px 1px rgba(0, 16, 85, 0.15), inset 8px 8px 24px 1px rgba(0, 93, 164, 0.1);
    font-family: 'YekanLight';
    margin-top: 12px;
    font-size: clamp(14px, 1.5vw, 16px);

}

.section-title {
    color: var(--dashboard-wallets-section-title);
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.crypto-icon {
    height: 16px;
    width: 16px;
}

.wallets {
    margin-top: 15px;
    white-space: nowrap;
    margin-bottom: 15px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    row-gap: 10px;
    column-gap: 50px;
}

.depsoits-section {
    background: var(--dashboard-section-bg);
    border-radius: 20px;
    flex-grow: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

}

.table-header {
    color: var(--dashboard-depsoits-table-headers);
    position: sticky;
    top: 0px;
    z-index: 1;
    background: var(--dashboard-section-bg);
}

.wallets-header {
    color: var(--dashboard-wallets-header);
    font-size: 13px;
    font-family: 'YekanLight';

}

.wallets-value {
    color: var(--dashboard-wallets-value);
    font-size: 13px;
    margin-top: 6px;
}

table {
    font-size: clamp(11px, 1.1vw, 12px);
    color: var(--dashboard-depsoits-table-data);
    width: 100%;
}

td {
    font-family: 'YekanLight';

}

.profit {
    color: var(--dashboard-depsoits-table-profit);
    stroke: var(--dashboard-depsoits-table-profit);

    font-weight: bold;
}

.negative-arrow {
    transform: rotate(180deg);
}

.change {
    stroke: var(--dashboard-depsoits-table-data);
}

.coin-title {
    display: flex;
    font-size: clamp(11px, 1.1vw, 12px);
    column-gap: 5px;
}

tr {
    margin-top: 13px;
    height: 30px;
}

@media(max-width:630px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width:550px) {
    .resp-2 {
        display: none;
    }

    //  .wallets{
    //     display: none;
    // }
}
</style>
