<template>
<div class="page-container" @click.self="$emit('close')">

    <div class="authcard">
        <div class="card-header">
            <inline-svg class="icon" :src="require('../../assets/icons/info.svg')" />
            <slot name="title">

            </slot>
            <inline-svg class="icon" @click="$emit('close')" :src="require('../../assets/icons/closecard.svg')" />
        </div>

        <div class="card-body">
            <slot name='body'>

            </slot>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.authcard {
    position: fixed;
    width: 90%;
    // top: 100px;
    flex-grow: 1;
    max-width: 394px;
    background: var(--authcard-bg);
    margin-top: 70px;
    z-index: 20;
    border-radius: 20px;
    padding: clamp(15px, 2.5vw, 32px);
}

.icon {
    stroke: var(--authcard-icon);
    cursor: pointer;
}

.authcard>div {
    width: 100%;
}

.authcard div {
    width: 100%;
}

.card-header {
    color: var(--authcard-title);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    font-size: clamp(16px, 1.6vw, 18px);
}

.card-body {
    margin-top: 23px;
    display: flex;
    // align-items: center;
    row-gap: 20px;
    flex-direction: column;
}

.page-container {
    display: flex;
    justify-content: center;
    width: 100%;
    // height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
}

.btn {
    background: var(--authcard-submit-btn);
    color: #F8F8FF;
}

img {
    cursor: pointer;
}
</style>
