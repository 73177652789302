<template>
<div class="banner ">
    <div class="banner-text">
        <h1 class="banner-title">به (اسم سایت) خوش آمدید</h1>
        <p class="middle-text">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد.</p>
        <div class="how-to-work">
            <img src="@/assets/public/runIcon.png" alt="">
            <p>
                اکسنپ چطور کار می‌کند؟
            </p>
        </div>
        <div class="register-container">
            <LabelInput :value="''" @input="value=>amount=value" :label="'ایمیل خود را وارد کنید'" type="text" />
            <button @click="$emit('signupModalToggle')" class="signup-btn">شروع کنید</button>
        </div>
        <div class="baner-coins">
            <div class="baner-coin" v-for="(coin , index) in cryptos.filter(a => a.marketType.includes('TOMAN'))" :key="index" :class="{'dark-baner-coin' : !state.lightTheme}">
                <div class="coins-info">
                    <div class="name">
                        {{$coinLabel[coin.relatedCoin]}} - تومان
                    </div>
                    <div class="numbers">
                        <div>
                            <span :class="{'positive-change' : coin.last24HourChange>0 , 'negative-change' : coin.last24HourChange<0}">
                                {{Math.abs(coin.last24HourChange).toFixed(2)}} %
                                <span v-if="coin.last24HourChange>0">+</span>
                                <span v-if="coin.last24HourChange<0">-</span>
                            </span>
                            <span>
                                {{$coinUnit[coin.relatedCoin]}}
                            </span>
                            <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" class="coin-image" alt="">
                        </div>
                        <div class="last-price">
                            {{$toLocal(coin.lastPrice,0)}}
                        </div>
                    </div>
                </div>
                <div class="coins-chart">
                    <CoinDataChart :data="coin.lastWeekData" />
                </div>
            </div>
        </div>
    </div>
    <div class="image-container">
        <!-- <img class="coins-system" src="../../../assets/public/home-banner-img.png"/>
            <img class="moving-coin" src='../../../assets/public/moving-coin.svg'/>
            <img class="moving-coin1" src='../../../assets/public/moving-coin.svg'/> -->
        <img v-if="state.lightTheme" class="main-image" src="@/assets/public/baner.svg" alt="">
        <img v-if="!state.lightTheme" class="main-image" src="@/assets/public/banerDark.svg" alt="">
        <img v-if="state.lightTheme" class="baner-background" src="@/assets/public/DBackHeader.svg" alt="">
        <img v-if="!state.lightTheme" class="baner-background" src="@/assets/public/DBackgroundDark.svg" alt="">
    </div>
</div>
</template>

<script>
import CoinDataChart from './CoinDataChart'
export default {
    name: 'Banner',
    props: {

    },
    components: {
        CoinDataChart
    },
    methods: {
        async getMarkets() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: true
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res
            }
        },
    },
    mounted() {
        this.getMarkets()
    },
    data() {
        return {
            cryptos: [],
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.image-container {
    // background: url('../../../assets/public/home-banner-img-bg.png');
    display: flex;
    justify-content: center;
    flex-grow: 1;
    // position: relative;

}

.moving-coin {
    position: absolute;
    animation-name: example;
    animation-duration: 14s;
    margin-right: 4%;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    width: 18%;
}

@keyframes example {
    0% {
        top: -1%
    }

    35% {
        top: 26%;
        transform: scale(0.7);
    }

    70% {
        top: 21%;
        transform: scale(1.32) rotate(-65deg);
    }

    100% {
        top: -1%;
        transform: scale(1);
    }
}

.moving-coin1 {
    position: absolute;
    animation-name: coin1;
    animation-duration: 14s;
    margin-right: -2%;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    width: 18%;
}

@keyframes coin1 {
    0% {
        top: 64%;
        transform: scale(0.52) rotate(-40deg);
    }

    35% {
        top: 54%;
        transform: scale(0.52) rotate(5deg);
    }

    70% {
        top: 61%;
        transform: scale(0.52) rotate(-90deg);
    }

    100% {
        top: 64%;
        transform: scale(0.52) rotate(-40deg);
    }

}

.banner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    // margin-top: 100px;
    position: relative;
    padding: 0 20px;
    row-gap: 20px;
}

.baner-background {
    max-width: 100%;
    position: absolute;
    left: 0;
    top: -80px;
}

.baner-coins {
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    widows: 100%;
    height: 337px;
    justify-content: space-between;
    row-gap: 25px;
    column-gap: 25px;
    padding: 5px;
}

.baner-coin {
    width: 300px;
    height: 142px;
    box-shadow: var(--box-shadow);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 23px 16px;
    flex-grow: 1;

}

.how-to-work {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: var(--how-work);
    font-size: 18px;
}

.coins-info {
    display: flex;
    // flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .name {
        color: var(--publicpage-markets-price);
    }

    .numbers {
        color: var(--publicpage-markets-price);

        .coin-image {
            width: 24px;
        }

        div {
            display: flex;
            align-items: center;
            column-gap: 5px;
        }
    }
}

.coins-chart {
    width: 100%;
}

.main-image {
    z-index: 2;
    max-width: 100%;
}

.banner-text {

    flex-basis: 350px;
    flex-grow: 1;
    z-index: 2;
    margin-top: 60px;
    padding-right: 80px;
}

.subtext {
    color: #FFBF00;
    font-size: clamp(13px, 1.3vw, 14px);

}

.banner-title {
    color: var(--banner-title);
    font-weight: bold;
    font-size: clamp(24px, 3vw, 36px);
}

.middle-text {
    color: var(--banner-middle-text);
    font-weight: lighter;
    font-size: clamp(14px, 1.2vw, 16px);
    line-height: 20px;
    font-weight: 300;
}

.signup-btn {
    margin-top: 6px;
    background: #FFCA2E;
    border-radius: 50px;
    padding: 9px 32px;
    color: #3D3D3D;
        min-width: 200px;
    flex-grow: 1;
    max-width: 200px;
}

.coins-bg {
    mix-blend-mode: lighten;
    opacity: 0.6;
    // position: absolute;
    width: 100%;

    // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);

}

.coins-system {
    max-width: min(80%, 528px);

    height: auto;
}

.dark-baner-coin {
    background: #0D1139;
}

.register-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 5px;
    margin: 15px 0;
    justify-content: center;
}

@media only screen and (max-width: 500px) {
    .baner-background {
        top: -50px;

    }

    .baner-coin {
        width: 230px;
    }

    .apexcharts-canvas {
        max-width: 100%;
    }

    .banner-text {
        padding-right: 0px !important;
    }

    .baner-background {
        left: 0 !important;
    }

    .baner-background,
    .last-price {
        display: none !important;
    }

    .banner-title,
    .middle-text {
        text-align: center;
    }
}
</style>
