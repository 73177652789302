<template>
<div class="switch">
    <div :class="{active:choice==item.value , 'sell':(item.value=='SELL' && choice==item.value),'buy':(item.value=='BUY' && choice==item.value)}" v-for="(item,index) in items " 
        :key="index" @click="choice=item.value;$emit('choose',choice)" >
        {{item.label}}
    </div>
</div>
  
</template>

<script>
export default {
  name: 'ToggleComp',
  props:{
      items:Array,
      defaultValue:String
    
  },
  data(){
      return {
          choice:this.defaultValue||this.items[0].value
      }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch{
    width: 100%;
    display: flex;
    font-size: clamp(12px,1vw,15px);
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    color:var(--switch-comp-inactive);
    border: 0.2px solid var(--switch-comp-border);
    border-radius: 50px;
    /* padding: 3px 16px; */
    min-height: 39px;
    background: var(--switch-comp-bg);
    overflow: hidden;
   
    
}
.switch > div{
      flex-grow: 1;
      display: flex;
      justify-content: center;
      white-space: nowrap;
      cursor: pointer;
      font-family: 'YekanLight';
      height: 39px;
      display: flex;
      align-items: center;
    
}
.active{
    color: #F2F5FA;
      font-family: 'YekanBold';
      background: var(--normal-btn);

    
}
.sell {
    background: #FF2D78 !important;
}
 .buy {
    background: #80B73B !important;
}
</style>