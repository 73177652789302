<template>
<div class="authentication">
    <form class="sections"   @submit.prevent="submit">
        
        <PersonalFields :readOnly="readonly" :data.sync="userInfo"  v-model="userInfo" />
        <BankInformation :status="status" :fullname="userInfo.firstName+' '+userInfo.lastName" :bankAccounts.sync="userInfo.bankAccounts" />
        <ImageUpload :readOnly="readonly" :data.sync="userInfo" />
        <!-- <div>{{userInfo.email}}</div> -->
        <p class="error" v-if="disabled">*{{disabled}}</p>

        <button v-if="!readonly" :disabled="disabled" :class="{'confirm-data-btn':true,'disabled-btn':disabled}" type="submit"> ثبت و ارسال اطلاعات </button>
        <button v-if="readonly" :disabled="disabled" :class="{'confirm-data-btn':true,'disabled-btn':disabled}" type="submit"> ویرایش اطلاعات </button>
    </form>

</div>
</template>

 
<script>
const statusLabel = {
    CONFIRMED_IDENTITY: 'اطلاعات هویتی تایید شده',
    AUTHORIZED: 'احراز هویت شده',
    UNAUTHORIZED: 'اطلاعات تکمیل نشده',
    PENDING: 'در انتظار تایید',
    NOT_COMPLETE: 'احراز هویت تکمیل نشده',
    UNIDENTIFIED: 'اطلاعات هویتی تایید شده',
    INFORMATION_CHANGED_PENDING: 'در انتظار تایید',
    INFORMATION_CHANGED: 'احراز هویت تکمیل نشده'
}
const inputLabel = {
    'firstName': 'نام',
    'lastName': 'نام خانوادگی',
    'email': 'ایمیل',
    'birthDay': 'تاریخ تولد',
    'mobileNumber': 'شماره موبایل',
    'telephone': 'شماره ثابت',
    'address': 'آدرس',
    'image': 'عکس',
}
import BankInformation from './BankInformation'
import PersonalFields from './PersonalFields'
import ImageUpload from './ImageUpload'
export default {
    name: 'Authentication',
    components: {
        ImageUpload,
        BankInformation,
        PersonalFields
    },
    data() {
        return {
            bankChange: 'noop',
            userInfo: {
                // authorized: this.state.userInfo.authorized,
                // pending: this.state.userInfo.pending,
                authenticationType: '',
                firstName: '',
                lastName: '',
                email: '',
                nationalCode: '',
                birthDay: '',
                mobileNumber: '',
                telephone: '',
                address: '',
                bankAccounts: [],
                image: '',
                document:''
            },
            duplicateBank: '',
            change: 0,
            status: 'AUTHORIZED'
        }
    },
    computed: {
        readonly() {
            return ['AUTHORIZED', "INFORMATION_CHANGED","CONFIRMED_IDENTITY"].includes(this.state.userInfo.authenticationType)
        },
        disabled(){
            for (let key in this.userInfo) {
                if (['firstName', 'lastName', 'email', 'nationalCode', 'birthDay', 'mobileNumber', 'telephone', 'bankAccounts', 'image'].includes(key)) {
                    if (key === 'bankAccounts') {
                        if (this.userInfo.bankAccounts.length) {
                            for (let data of this.userInfo.bankAccounts) {
                                if (!data.shebaCode || !data.cardNumber || data.cardNumber.replace(/-/g, '').length < 16) {
                                    return 'اطلاعات بانکی اشتباه است';
                                }

                            }
                        } else {
                            return 'حداقل یک حساب بانکی اضافه نمایید';
                        }
                    } else if (key === 'nationalCode') {
                        if (this.userInfo[key] < 10) {
                            return 'کدملی اشتباه است';
                        }
                    } else if (!this.userInfo[key]) {
                        return inputLabel[key] + ' اجباریست'
                    }
                }
            }
            return false
        },
        label() {
            return statusLabel[this.state.userInfo.authenticationType]
        },

    },
    methods: {
        async check() {
            this.state.loading = true;
            const res = await this.$axios(`/users/account?imageNeeded=true`)
            this.userInfo = res.baseDTO
            this.userInfo.bankAccounts = res.baseDTO.bankAccounts.length ?
                res.baseDTO.bankAccounts :
                []

            this.change++

        },
        removeDash(items) {
            for (let item of items) {
                item.cardNumber = item.cardNumber.replace(/-/gi, '');
            }
        },
        bankCheck(items) {

            for (let item of items) {

                if (!item.cardNumber || !item.shebaCode) {
                    this.$error('اطلاعات ناقص', "شماره شبا یا کارت بانکی را وارد نکرده اید");
                    return false;
                } else {
                    return true;
                }
            }
        },
        dataURLtoFile(dataurl, filename) {

            let arr = dataurl.split(":");
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);

            let n = bstr.length;
            let u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {
                type: mime
            });
        },
        async submit() {

            this.state.loading = true;
            // this.removeDash(this.userInfo.bankAccounts);
            let form_data = new FormData();
            for (let key in this.userInfo) {
                if (key === 'image' && !this.readonly) {
                    if (typeof this.userInfo.image !== 'string') {
                        form_data.append("file", this.userInfo.image);
                    } 
                    else {
                        form_data.append("file", this.dataURLtoFile(this.userInfo.image, "image"));
                    }
                }
                form_data.append(key, this.userInfo[key]);
                console.log('dsfdsadsf')
            }
            form_data.append("bankAccountsString", JSON.stringify(this.userInfo.bankAccounts));
            for (var value of form_data.values()) {
                console.log(value);
            }
            if (this.bankCheck(this.userInfo.bankAccounts)) {
                try {

                    let vm = this
                    const config = {
                        onUploadProgress: function (progressEvent) {
                            vm.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        }
                    }
                    const res = await this.$axios.post("/users/account", form_data, config)

                    this.state.userInfo.authenticationType = res.baseDTO.authenticationType
                    this.state.userInfo.email = res.baseDTO.email
                    this.state.userInfo.authorized = res.baseDTO.authenticationType === "AUTHORIZED"
                    this.state.userInfo.pending = res.baseDTO.authenticationType === "PENDING"
                    await this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success')

                    if (res.message.includes("need to be verified")) {
                        this.state.loading = true
                        await this.$axios(`/finotech/send-sms-to-customer?email=${this.userInfo.email}`)
                        this.$router.push('/Panel/fino-verifying')
                    } else {
                        this.$getuserInfo()
                    }
                } catch (e) {
                    if (e.response && e.response.data.message.includes("ShebaCode or CardNumber Is For Another User")) {
                        this.$error('', ' شماره شبا یا کارت قبلا وارد شده است.')
                        this.duplicateBank = e.response.data.message;

                    }
                }
            }
            this.state.loading = false
        },
    },
     mounted() {
        this.check()

    }
}
</script>

<style scoped>
.confirm-data-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--normal-btn);
    box-shadow: var(--box-shadow);
    border-radius: 50px;
    max-width: 300px;
    padding: 8px;
    font-size: clamp(14px, 1.1vw, 16px);
    color: #fff;
}

.authentication {
    background: var(--background-childs);
    padding: 16px;
    border-radius: 20px;
    width: 100%;
}

::v-deep .section-title {
    color: var(--authentication-section-title);
    font-size: 16px;

}

.sections {
    display: flex;
    flex-direction: column;
    /* padding: 16px 0px 40px 24px; */
    row-gap: 30px;
    flex-grow: 1;

}

.disabled-btn {
    opacity: 0.8;
}

::v-deep .input-fields {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
}

::v-deep .input-fields>div {
    flex-basis: 260px;
    flex-grow: 1;
}

::v-deep .input-group-title {
    font-size: clamp(12px, 1.3vw, 14px);
    white-space: nowrap;
    padding: 10px 0px;

    color: #4f4f4f;
}
</style>
