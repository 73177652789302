<template>
<div class="code-input-container">
    <CodeInput dir="ltr" class="input" 
    :fields="num" ref="code-input" :fieldWidth="33" 
    :fieldHeight="28" :loading="false" @change="e=>$emit('change',e)" 
    @complete="e=>$emit('complete',e)" />

</div>
</template>

<script>
import CodeInput from "./CodeInput.vue";
export default {
    name: 'VerifyCodeInput',
    props: {
        num: Number
    },
    data() {
        return {
            code: []
        }
    },
    components: {
        CodeInput

    },
    methods: {
       
        reset() {
            this.code = ''
            for (let i = 0; i < this.num; i++) {
                this.$refs['code-input'].values[i] = ''
                this.$refs['code-input'].$refs['input_' + i][0].value = ''
            }
            this.$refs['code-input'].$refs['input_0'][0].focus()
        },

    },
  

}
</script>

<style scoped>
::v-deep input[type="tel"]::placeholder {
    color: var(--main-color);
}

::v-deep .input {
    width: 100% !important;
 
}
.code-input-container{
  overflow: hidden;
  max-height: 40px;
}
::v-deep .react-code-input {
    display: flex;
    align-items: center;

    justify-content: space-evenly;
    background:var(--codeInputBg) ;
    height: 44px;
    width: 100%;
    border-radius: 6px;
}

::v-deep input {
    border: none !important;
    color: var(--font-color3) !important;
    font-family: 'VistaDev', sans-serif !important;
    font-size: clamp(16px, 2vw, 24px);
    width: 100% !important;
    height: 36px !important;
    border-radius: 50px !important;
    letter-spacing: -2px;
    text-align: center !important;
    border: 0.2px solid var(--label-input-border) !important;
    background: var(--label-input-bg);
    color: var(--label-input-text) !important;
}

</style>
