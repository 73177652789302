<template>
<div class="chart">
    <apexchart id='chart' type="line" width="270" height='50' ref="chart" :options="chartOptions" :series="series"></apexchart>
</div>
</template>

<script>
export default {
    name: 'CoinDataChart',
    props: {
        data: Array
    },
    data() {
        return {
            series: [{
                data: []
            }],
            chartOptions: {
                chart: {
                    
                    width:'100%',
                    type: 'line',
                    sparkline: {
                        enabled: true,
                    }
                },
                stroke: {
                    width: 1,
                    curve: 'smooth'
                },
                tooltip: {
                    enabled: false
                },

                colors:['#304FDD']

            },
        }
    },
    mounted() {
        this.series = [{
            data: this.data
        }]
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style >
#apexchartsukxchwrt {
    width: 270px !important;
}
.apexcharts-svg {
    width: 100%;
}
.chart {
    /* max-height: 40px; */
}
</style>
