<template>
<div class="tickets-page">
    <AddTicket @modalToggle="(value)=>modalType=value" :ticket='tickets.find(ticket=>ticket.id==activeId)' @addTicket="data=>handleTicket(data)" v-if="modalType=='AddTicket'" />
    <div class="ticket-page-header">
        <div>تیکت</div>
        <button v-if="activeId" class="add-ticket-btn" @click="modalType='AddTicket'">ثبت پیام جدید</button>
        <button v-else class="add-ticket-btn" @click="modalType='AddTicket'">ثبت تیکت جدید</button>
    </div>
    <div class="tickets-table">
        <div class="table-header">
            <div class="resp-1">#</div>
            <div class="resp-1">تاریخ</div>
            <div>عنوان تیکت</div>
            <div class="resp-2">موضوع</div>
            <div>وضعیت</div>
            <div>رضایت از پشتیبانی</div>
        </div>

        <div class="ticket" v-for="(ticket,index) in tickets" :key="index">
            <div v-if="activeId==ticket.id || !activeId">
                <div :class="{'table-row':true,'active-ticket':ticket.id==activeId}">
                    <div class="id resp-1">{{ticket.id}}</div>
                    <div class="resp-1">{{$G2J(ticket.createdAtDateTime)}}</div>
                    <div class="pointer " @click="showMessage(ticket.id)">{{ticket.subject}}</div>
                    <div class='resp-2'>{{typeLabel[ticket.type]}}</div>
                    <div :class="{'status-checking':ticket.status=='PENDING','status-open':ticket.status=='OPENED'
                    ,'status-closed':ticket.status=='CUSTOMER_ANSWERED','status-closed':ticket.status=='ADMIN_ANSWERED'}">{{statusLabel[ticket.status]}}</div>
                    <div @click="ticket.rate=!ticket.rate" :class="{pointer:true,rate:true,'positive-rate':ticket.rate}">
                        <inline-svg :class="{'positive-rate':ticket.rate}" :src="require('../../assets/icons/star.svg')" /> از پشتیبانی راضی هستم</div>
                </div>
                <div v-if="activeId==ticket.id" class="chat-screen">
                    <div :class="{'message-container':true,'user-message':message.customer,
                  'admin-message':message.admin}" v-for="(message,index) in messages" :key="index">
                        <div class="message">
                            <div class="message-header">
                                <span class="message-author" v-if="message.admin">ادمین</span>
                                <span class="message-author" v-else>شما</span>
                                <span class="message-date">{{message.date}}</span>
                            </div>
                            <div class="message-content">{{message.text}}</div>
                            <div  @click.prevent="modalType='AddTicket'" v-if="message.admin" class="message-reply-btn">پاسخ</div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
</template>

<script>
import AddTicket from '../Modals/AddTicket'
export default {
    name: 'Ticket',
    components: {
        AddTicket,
    },
    
    methods: {
        handleTicket(formData) {

            if (this.activeId) {
                this.sendMessage(formData)

            } else {
                this.addTicket(formData)

            }
            console.log(formData)
        },

        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/tickets')
            this.tickets = res.content
        },
        async showMessage(id) {
            if (this.activeId == id) {
                this.activeId = null
            } else {
                this.state.loading = true
                this.activeId = id
                const res = await this.$axios.get('/tickets/message/' + this.activeId)
                this.messages = res.pageDTO.content.reverse()
                this.reload = !this.reload
                this.state.loading = false
            }

        },
        async showMessageAgain(index) {
            this.state.loading = true
            const res = await this.$axios.get('/tickets/message/' + index)
            this.messages = res.pageDTO.content.reverse()
            this.reload = !this.reload
            this.state.loading = false
        },

        async sendMessage(formData) {
            this.state.loading = true
            const res = await this.$axios.post('/tickets/message/' + this.activeId, formData)
            this.messages.push(res.baseDTO)
            this.showMessageAgain(this.activeId)
        },
        async addTicket(formData) {
            this.state.loading = true
            await this.$axios.post('/tickets', formData)
            // this.$push({
            //     name: 'SupportMassage',
            //     params: {
            //         id: res.baseDTO.id
            //     }
            // })

        }

    },
    mounted() {
        this.getData()
    },

    data() {
        return {
            typeLabel:{
                TECHNICAL: 'فنی',
                GENERAL: 'عمومی',
            },
            statusLabel:{
                OPENED: 'باز',
                PENDING: 'در انتظار پاسخ',
                CUSTOMER_ANSWERED: 'پاسخ مشتری',
                ADMIN_ANSWERED: 'پاسخ پشتیبانی',
            },
            content: [{}],
            messages: [],
            activeId: '',
            reload: false,
            data: {
                type: '',
                subject: '',
                text: '',
            },
            types: [{
                    label: 'فنی',
                    value: 'TECHNICAL'
                },
                {
                    label: 'عمومی',
                    value: 'GENERAL'
                }
            ],
            modalType: '',
            activeIndex: null,
            tickets: [
                // {
                //       date: '00/01/01 12:34:45',
                //       title: 'خرید بیت کوین',
                //       subject: 'خرید',
                //       status: 'CHECKING',
                //       rate: false,
                //       messages: [{
                //               author: 'شایان کریمی',
                //               date: '۰/۰۳/۱۹-۱۱:۳۴',
                //               content: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'
                //           },
                //           {
                //               author: 'admin',
                //               date: '۰/۰۳/۱۹-۱۱:۳۴',
                //               content: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'
                //           },
                //           {
                //               author: 'شایان کریمی',
                //               date: '۰/۰۳/۱۹-۱۱:۳۴',
                //               content: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'
                //           },
                //           {
                //               author: 'شایان کریمی',
                //               date: '۰/۰۳/۱۹-۱۱:۳۴',
                //               content: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'
                //           },
                //           {
                //               author: 'admin',
                //               date: '۰/۰۳/۱۹-۱۱:۳۴',
                //               content: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'
                //           },
                //       ]
                //   },

            ],
         
        }
    },

}
</script>

<!-- Add "scoped" atdivibute to limit CSS to this component only -->

<style lang="scss" scoped>
.rate {
    stroke: var(--tickets-blue);
    display: flex;
    align-items: center;
    color: var(--tickets-blue);
    column-gap: 5px;
}

.pointer {
    cursor: pointer;
}

.positive-rate {
    fill: #FFBF00;
    stroke: #FFBF00;
    color: #FFBF00;
}

.message {
    padding: 8px;
    flex-grow: 1;
    max-width: min(614px, 90%);
    background: var(--dashboard-section-bg);
}

.chat-screen {
    display: flex;
    flex-direction: column;

}

.message-container {
    display: flex;
    margin-top: 16px;
}

.user-message {
    justify-content: flex-start;

}

.user-message>.message {
    border-radius: 20px 10px 0px 20px;
}

.admin-message {
    justify-content: flex-end;
}

.admin-message>.message {
    border-radius: 10px 20px 20px 0px;

}

.message-content {
    font-size: clamp(11px, 1.2vw, 13px);
    color: var(--tickets-message-text);
    font-family: 'YekanLight';
}

.message-date {
    font-size: clamp(11px, 1.1vw, 12px);
    color: var(--tickets-message-date);
}

.message-author {
    font-size: clamp(13px, 1.3vw, 14px);
    color: var(--tickets-message-author);
}

.message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message-reply-btn {
    font-size: clamp(12px, 1.2vw, 14px);
    color: var(--tickets-blue);
    cursor: pointer;
}

.tickets-table {
    width: 100%;
    margin-top: 16px;
}

.id {
    color: var(--tickets-blue);
    padding-left: 20px;
}

.table-header>div:first-child {
    padding-left: 20px;
}

.status-checking {
    color: #FFBF00;
}

.status-open {
    color: var(--tickets-blue);

}

.active-ticket {
    background: var(--tickets-table-focus);
}

.status-closed {
    color: #FF2D78;
}

.table-header {
    display: grid;
    grid-template-columns: 1fr repeat(5, 4fr);
    font-size: clamp(12px, 1.3vw, 14px);
    color: var(--tickets-table-header);
    padding: 0px 16px;
}

.table-row {
    color: var(--tickets-table-row);
    font-size: clamp(11px, 1.2vw, 12px);
    font-family: 'YekanLight';
    display: grid;
    height: 40px;
    align-items: center;
    padding: 0px 16px;
    grid-template-columns: 1fr repeat(5, 4fr);
    border-radius: 30px;

}

.table-row:hover {
    background: var(--tickets-table-focus);
}

.add-ticket-btn {
    background: var(--tickets-addbtn-bg);
    color: var(--tickets-addbtn-text);
    border: 1px solid var(--tickets-addbtn-border);
    border-radius: 50px;
    font-size: clamp(14px, 1.5vw, 16px);
    padding: 4px;
    flex-grow: 1;
    max-width: 253px;
}

.ticket-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    font-size: clamp(18px, 2.1vw, 24px);
    color: var(--tickets-blue);
}

.tickets-page {
    background: var(--dashboard-section-bg);
    padding: 18px 16px;
    flex-grow: 1;
    border-radius: 20px;
}

@media(max-width:1000px) {
    .resp-1 {
        display: none;
    }

    .table-row {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .table-header {
        grid-template-columns: 1fr 1fr 1fr 1fr;

    }
}

@media(max-width:500px) {
    .resp-2 {
        display: none;
    }

    .table-row {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .table-header {
        grid-template-columns: 1fr 1fr 1fr;

    }
}
</style>
