<template>
<div class="invite-friends">
    <div class="page-title">دعوت دوستان</div>
    <p>لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا خوانا و واضح ، دقیقا مانند تصویر نمونه ، ارسال نمایید . دقت شود متن دست نوشته نبایستی تایپ شود. هم چنین توجه داشته باشید هنگام تهیه تصویر ، متن دست نوشته و اطلاعات کارت ملی بصورت برعکس و آینه ای ثبت نشود.</p>
    <div class="content-section">
        <div class="invite-details">
            <div class="invite-values">
                <div class="invite-label-value">
                    <div class="label">مجموع سود من</div>
                    <div class="value">{{$toLocal(inviteData.profit,0)}}&nbsp;تومان</div>
                </div>
                <div class="invite-label-value">
                    <div class="label">تعداد دوستان من</div>
                    <div class="value">{{inviteData.countInvitees}}&nbsp;نفر</div>
                </div>
            </div>
            <div class="label-link">
                <div class="copy-alert" v-if="codeAlertShow">copied</div>
                <div>کد دعوت:</div>
                <div>
                    <div class="code"> {{code}}</div>
                    <inline-svg class="copy-icon" @click="copyCode()" :src="require('../../assets/icons/copy.svg')" />
                </div>
            </div>
            <div class="label-link">
                <div class="copy-alert" v-if="linkAlertShow">copied</div>
                <div>لینک دعوت:</div>
                <div>
                    <div class="code">{{link}}</div>
                    <inline-svg class="copy-icon" @click="copyLink()" :src="require('../../assets/icons/copy.svg')" />
                </div>
            </div>

        </div>

        <div class="image-container">
            <img v-if="state.lightTheme" class="page-img" src="../../assets/public/invite.svg" />
            <img v-if="!state.lightTheme" class="page-img" src="../../assets/public/inviteDark.svg" />
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data() {
        return {
           link: '',
            code: '',
            profit: '',
            friendsNumber: '',
            codeAlertShow: false,
            linkAlertShow: false,
            inviteData: {
                countInvitees: '',
                profit: '',
                referralCode: ''
            }

        }
    },
    methods: {
        copyLink() {
            navigator.clipboard.writeText(this.link)
            this.linkAlertShow = true;
            setTimeout(() => {
                this.linkAlertShow = false;
            }, 2000)
        },
        copyCode() {
            navigator.clipboard.writeText(this.code)
            this.codeAlertShow = true;
            setTimeout(() => {
                this.codeAlertShow = false;
            }, 2000)
        },
        async getData() {
            this.state.loading=true
            const res = await this.$axios.get('/users/invites');
            this.inviteData = res.baseDTO;
            this.link = 'xxxxxx.com/SignUp?refer_code=' + this.inviteData.referralCode
            this.code = this.inviteData.referralCode
            this.state.loading=false
        }
    },
    mounted(){
        this.getData()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.code{
    // font-family: Latha, "Tamil MN", serif;
}
.image-container {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    margin-top: 20px;

}

.copy-alert {
    clip-path: polygon(0 21%, 100% 21%, 100% 75%, 66% 75%, 50% 100%, 32% 75%, 0 75%);
    background: #666666;
    border-radius: 10px;
    width: 40px;
    font-size: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    height: 30px;
    color: white;
    position: absolute;
    top: -20px;
    left: 50px;
}

.page-img {
    max-width: min(80%, 528px);
    height: auto;
}

.label-link {
    border-radius: 30px;
    padding: 12px 16px;
    font-size: clamp(14px, 1.6vw, 16px);
    color: var(--invite-friends-label-link-text);
    background: var(--invite-friends-label-link-bg);
    display: flex;
    align-items: center;
    column-gap: 13px;
    justify-content: space-between;
    border: 1px solid var(--invite-friends-label-link-border);
    flex-wrap: wrap;
    position: relative;
}

.label-link>div {
    display: flex;
    align-items: flex-start;
    column-gap: 5px;
}

.page-title {
    font-size: clamp(18px, 2.2vw, 24px);
    color: var(--invite-friends-page-title);
}

.copy-icon {
    min-width: 19px;
    cursor: pointer;
}

.invite-friends {
    background: var(--dashboard-section-bg);
    box-shadow: 0px 0px 64px rgba(28, 57, 187, 0.05);
    border-radius: 20px;
    padding: 16px;
}

p {
    color: var(--invite-friends-text);
    font-size: clamp(12px, 1.3vw, 14px)
}

.content-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 100px;
}

.invite-details {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.invite-values {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 25px;
}

.invite-label-value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.value {
    color: #FFBF00;
    font-weight: bold;
    font-size: clamp(28px, 3.2vw, 36px);
}

.label {
    color: var(--invite-friends-label);
    font-size: clamp(14px, 1.6vw, 18px);
    font-weight: bold;
}
</style>
