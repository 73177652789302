<template>
    <div class="auth-container" :class="{'set-back' : !state.lightTheme}">
        <div class="form-container">
            <!-- <Login v-if="$route.name == 'Login'"/> -->
            <Register v-if="$route.name == 'Register'"/>
            <!-- <TwoStep v-if="$route.name == 'TwoStep'"/>
            <ResetPass v-if="$route.name == 'ResetPass'"/>
            <RecoveryPass v-if="$route.name == 'RecoveryPass'"/> -->
        </div>
        <div class="image-container">
            <!-- <img v-if="state.lightTheme && ($route.path == 'login' || $route.path == 'login-twoFA')" src="@/assets/public/log1Light.svg" alt=""> -->
            <img v-if="state.lightTheme" src="@/assets/public/signup.svg" alt="">
            <img v-if="!state.lightTheme" src="@/assets/public/signupDark.svg" alt="">
            
            <!-- <img v-if="state.lightTheme && ($route.path == 'forgetpassword' || $route.path == 'forgetpassword-twoFA' || $route.path == 'set-password')" src="@/assets/public/log3Light.svg" alt="">
            <img src="@/assets/public/log1Dark.svg" alt="">
            <img src="@/assets/public/log2Dark.svg" alt="">
            <img src="@/assets/public/log3Dark.svg" alt=""> -->
        </div>
        <img v-if="state.lightTheme" class="background" src="@/assets/public/AuthLight.svg" alt="">
        <img v-if="!state.lightTheme" class="background" src="@/assets/public/AuthDark.svg" alt="">
    </div>
</template>
<script>
// import Login from './Login.vue'
import Register from './Register.vue'
// import TwoStep from './TwoStep.vue'
// import ResetPass from './ResetPass.vue'
// import RecoveryPass from './RecoveryPass.vue'
export default {
    name:'AuthenticationParent',
    components:{
        // Login ,
     Register ,
    //  TwoStep,ResetPass,RecoveryPass
     },
    data() {
        return {
            
        }
    },
}
</script>
<style lang="scss" scoped>
    .auth-container {
        width: 100%;
        display: flex;
        // max-width: 1400px;
        padding: 0 20px;
        margin-top: 50px;
        min-height: calc(100vh - 50px);
        align-items: center;
        justify-content: space-around;
        position: relative;
        flex-wrap: wrap;
    }
    .background {
        position: absolute;
        bottom: 0;
        left: 0;
        // z-index: -1;
    }
    .form-container , .image-container {
        z-index: 2;
    }
   

</style>