<template>
<ModalCard @close="$emit('modalToggle','')">
     <template #title>
        جزئیات درﺧﻮاﺳﺖ 
    </template>
     <template #body>
         <div>
             <p class="text">درخواست شما به شرح زیر است در صورت اطمینان تایید فرمایید</p>
             <div class='detail'>
                 <span  class='detail-title'>نوع درخواست</span>
                 <span class="amount" :class="{buy:order.tradeType=='BUY' ,sell:order.tradeType=='SELL'}">{{dict[order.tradeType]+' '+dict[order.orderType]}}</span>
             </div>  
             <div class='detail'>
                 <span class='detail-title'> مقدار</span>
                 <span  dir="ltr">
                     &nbsp;{{$coins[order.coin].code}}
                   <span class="amount">{{order.amount}}</span> 
                   </span>
             </div>  
             <div class='detail'>
                 <span class='detail-title'>قیمت واحد</span>
                 <span >
                    <span class="amount">{{ $toLocal(order.unitPrice,$decimal[order.baseCoin])}}</span>
                    &nbsp;{{$coins[order.baseCoin].persianName}}</span>
             </div>  
              <div class='detail'>
                 <span class='detail-title'> مجموع</span>
                 <span >
                   <span class="amount">{{$toLocal(sum,$decimal[order.baseCoin])}}</span> 
                    &nbsp;{{$coins[order.baseCoin].persianName}}</span>
             </div>   
            
         </div>
         <button class="modal-submit-btn" @click="$emit('confirm')" >تایید </button>
     </template> 
   

  </ModalCard>
    
</template>

<script>
import ModalCard from './ModalCard'
export default {
  name: 'AddTicket',
  components:{
      ModalCard,
  },
  props:['order'],
  computed:{
      sum(){
          return this.order.unitPrice * this.order.amount
      }
  },
  data(){
    return{
        dict:{
            "MARKET":'بازار',
            "LIMIT":'حد',
            "EXCHANGE":'صرافی',
            "BUY":'خرید',
            "SELL":'فروش'
        }
      
    }
  }
}
</script>
<style scoped>
.text{
    font-size: clamp(13px,1.4vw,15px);
    color:var(--modal-text)

}
.buy{
    color: var( --modal-orderType-buy);
}
.sell{
    color: rgba(245, 43, 43, 1);;
}
.details{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.detail {
    margin: 10px 0;
}
.detail , .detail *{
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    font-size: clamp(12px,1.4vw,14px);
    font-family: 'YekanLight';
    color: var( --modal-orderType-buy);
}
.detail-title {
     color:var(--modal-text)
}
.amount {
    color: var(--otc-trade-text);
}
</style>