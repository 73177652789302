<template>
<div class="homepage">
   <Banner @signupModalToggle="$emit('signupModalToggle')"/>
   <!-- <CryptoCarousel/> -->
   <Events/>
   <div class="markets-container" :class="{'market-back' : state.lightTheme , 'market-back-dark' : !state.lightTheme}">
     <Markets/>
   </div>
   <div class="advantages-container" :class="{'advantage-light':state.lightTheme , 'advantage-dark' : !state.lightTheme}">
   <Advantages/>
   </div>
   <Support/>
   <!-- <Comments/> -->
   <!-- <BlogPosts/> -->
   <div class="easy" :class="{'easy-light':state.lightTheme , 'easy-dark' : !state.lightTheme}">
     <EasyTrade/>
   </div>
</div>

   
</template>

<script>
import Banner from './Banner'
// import CryptoCarousel from './CryptosCarousel'
import Events from './Events'
import Advantages from './Advantages'
import Markets from './Markets'
import Support from './Support'
// import Comments from './Comments'
// import BlogPosts from './BlogPosts'
import EasyTrade from './EasyTrade'
export default {
  name: 'homepage',
  components:{
      Banner,
      // CryptoCarousel,
      Events,
      Markets,
      Advantages,
      Support,
      // Comments,
      // BlogPosts,
      EasyTrade
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.homepage{
  // width:85%;
  // margin-bottom:200px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
  // padding: 0 15px;
}
.markets-container {
  width: 100%;
  flex-grow: 1;
}
.market-back {
    background-image: url('../../../assets/public/marketBack.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    padding: 50px 0;
}
.market-back-dark {
    background-image: url('../../../assets/public/marketBackDark.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    padding: 50px 0;
}
.advantages-container {
  width: 100%;
   min-height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
        background-position: center;
}
.advantage-light {
   background-image: url('../../../assets/public/everyBackLight.svg');
}
.advantage-dark {
  background-image: url('../../../assets/public/everyBackDark.svg');
}
.easy {
  width: 100%;
  min-height: 700px;
  background-repeat: no-repeat;
      background-position-x: left;
    background-position-y: bottom;
    margin-top: 50px;
}
.easy-light {
  background-image: url('../../../assets/public/easyBackLight.svg');
}
.easy-dark {
  background-image: url('../../../assets/public/easyBackDark.svg');
}
</style>
