<template>
<div class="authcard">
    <div class="card-header">
        <p>
            ایجاد حساب کاربری
        </p>
    </div>
    <div class="card-body">
        <LabelInput @input="value=>{data.email=value;emailError=''}" :errorText="emailError" label="آدرس ایمیل" />
        <LabelInput @input="value=>data.password=value" type='password' label="رمز عبور" />
        <LabelInput @input="value=>data.referralCode=value" label="کد دعوت(اختیاری)" />
        <div class="terms"><input type="checkbox" @click="check=!check">
            <div class="terms-text">
              <p> با ثبت نام در نام سایت شرایط و قوانین استفاده از سرویس‌های سایت را می‌پذیرید.</p> 
                </div>
        </div>

        <div class="captcha-container">
            <img src='../../assets/public/captcha-code.png' />
        </div>
        <LabelInput @input="value=>data.securityCode=value" type='securityCode' label="کد امنیتی" />

        <div class="btns">
            <button :class="{'auth-submit-btn':true,disabledbtn:disable  }" :disabled="disable" >تکمیل ثبت نام</button>
            <button class="google-btn">
                ادامه با حساب گوگل
            </button>
        </div>
        <div class="suggest"><span> حساب کاربری دارید؟</span><a ><span>وارد شوید</span> </a></div>
    </div>
</div>
</template>

<script>
// import AliInput from "@/components/tools/AliInput";

export default {
    name: "Register",
    components: {
        // AliInput
    },
    data() {
        return {
            data: {
                email: '',
                password: '',
                osType: '',
                deviceType: '',
                browser: '',
                referralCode: ''
            },
            emailError: '',
            passError: '',
            agree: false
        }
    },

    computed: {
        disable() {
            return !this.data.email || !this.data.password || this.emailError.length > 0 || this.passError.length > 0 || !this.agree
        },
        readOnly() {
            return localStorage.referralCode || this.$route.query.ref
        }
    },

    methods: {
        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {
            await this.checkEmail()
            await this.check2Pass()
            this.state.loading = true
            const res = await this.$axios.post('/users/register', this.data)
            if (res.message === 'user created successfully , verification email sent') {
                if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                    this.$cookies.set('username', this.data.email)
                    this.state.userInfo.email = this.data.email
                    await this.$router.push('/two-step-email')
                }
            } else if (res.token) {
                this.$setToken(res.token, this.state.userInfo.remember)
                await this.$router.push('/profile')
            }
        },
        async check2Pass() {
            if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                this.passError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                return Promise.reject('wrong pass : ' + this.data.password)
            } else {
                this.passError = ''
            }
        },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.emailError = 'ایمیل صحیح وارد نمایید'
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed : ' + this.data.email)
            } else {
                this.emailError = ''
            }
        },

        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },

    },
    mounted() {
        this.getData()
        if (this.$route.query.ref || localStorage.referralCode) {
            localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
            this.data.referralCode = this.$route.query.ref || localStorage.referralCode
        }
    }
}
</script>

<style scoped>
h3 {
    margin-bottom: 50px;
}

.Auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 470px;
    height: 100%;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.link {
    text-decoration: underline;
    font-size: 14px;
    align-self: flex-end;
    margin-bottom: 10px;
    cursor: pointer;
}

.normal-btn {
    margin: 20px 0;
}
.authcard {
    /* position: fixed; */
    /* width: 90%; */
    /* // top: 100px; */
    flex-grow: 1;
    max-width: 394px;
    min-width: 300px;
    background: var(--authcard-bg);
    margin-top: 70px;
    z-index: 20;
    border-radius: 20px;
    padding: clamp(15px, 2.5vw, 32px);
}

.icon {
    stroke: var(--authcard-icon);
    cursor: pointer;
}

.authcard>div {
    width: 100%;
}

.authcard div {
    width: 100%;
}

.card-header {
    color: var(--authcard-title);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    font-size: clamp(16px, 1.6vw, 18px);
}

.card-body {
    margin-top: 23px;
    display: flex;
    /* // align-items: center; */
    row-gap: 20px;
    flex-direction: column;
}

.page-container {
    display: flex;
    justify-content: center;
    width: 100%;
    /* // height: 100vh; */
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
}

.btn {
    background: var(--authcard-submit-btn);
    color: #F8F8FF;
    
}
.btns {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
img {
    cursor: pointer;
}
 p {
        text-align: center !important;
        width: 100%;
         color: var(--authcard-title);
    }
    .terms {
            display: flex;
    align-items: center;
    }
    span {
         color: var(--authcard-title);
    }
</style>
